import "@babel/polyfill";

import '../css/lib/bootstrap-reboot.css';
import '../scss/app.scss';


import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import smoothScroll from 'smooth-scroll';

$(function() {
	spMenu();
	spFooter();
});

function spMenu() {
	$('.header__open--sp').click(function() {
		$('.header__open--sp').css('display', 'none');
		$('.header__close--sp').css('display', 'block');
		$('.header__menu').css('display', 'block');
	})

	$('.header__close--sp').click(function() {
		$('.header__open--sp').css('display', 'block');
		$('.header__close--sp').css('display', 'none');
		$('.header__menu').css('display', 'none');
	})
}

function spFooter() {
	$('.footer__sp-menu').click(function() {
		$('.sp-footer__menu').css('display', 'inline-block');
		$('.sp-footer__social').css('display', 'none');
	})

	$('.footer__sp-social').click(function() {
		$('.sp-footer__menu').css('display', 'none');
		$('.sp-footer__social').css('display', 'inline-block');
	})
}